import React, { Component } from 'react'
import { createGlobalStyle } from 'styled-components'
import '../../assets/scss/app.scss'
import media from '../../styles/media'
import theme from '../../styles/theme'
import Contact from '../Contact'
import Footer from '../Footer'
import Head from '../Head'
import Header from '../Header'
import { OuterContainer, PageWrap, StyledThemeProvider } from './style'

const GlobalStyle = createGlobalStyle`
  html {
    body {
      touch-action: manipulation;
      #___gatsby {
      }
      img,
      a,
      a:hover,
      a:focus,
      button,
      button:hover,
      button:focus {
        outline: medium none !important;
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }    
      .react-datepicker-wrapper,.react-datepicker__input-container{
          display: block;
      }  
      .react-datepicker__header {
        background: black;
        .react-datepicker__current-month {
          text-transform: capitalize;
          color:white;
        }
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            text-transform: capitalize;
            color:white;
          }
        }
      }
      .react-datepicker__year-option, 
      .react-datepicker__month-option, 
      .react-datepicker__month-year-option,
      .react-datepicker__year-option--selected, 
      .react-datepicker__month-option--selected, 
      .react-datepicker__month-year-option--selected {
        color: black !important;
      }
      .react-datepicker__day--selected {
        background: #c59d5f;
      }
      .ReactModal__Overlay{
        z-index:101
      }
    }
    .ReactModal__Body--open {
      overflow: hidden;
      @media screen and (max-width: 991px) { 
        position: fixed;
        bottom: 0;
        width: 100%;
      }
      .ReactModal__Overlay.ReactModal__Overlay--after-open {
        height: 100vh;
        overflow: auto;
        .ReactModal__Content.ReactModal__Content--after-open {
          &.regular-reservation {
            position: absolute;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255) none repeat scroll 0% 0%;
            overflow: auto;
            border-radious: 4px;
            outline: currentcolor none medium;
          }
          ${media.sm`
            max-width: 1200px;
            width: 100%;
            &.regular-reservation {
              height: auto;
            }
          `}
          @media screen and (max-width: 767px) { 
            width: 100% !important;
            max-height: 100vh;
          }
        }
      }
    }
  }
`
declare global {
  interface Window {
    dataLayer: any
  }
}

interface Props {
  children: any
  themeType?: string
  currentPage?: string
}

interface State {
  isModalOpen?: boolean
}

class Layout extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }))
  }

  render() {
    const { isModalOpen } = this.state
    const { children, themeType = 'transparent', currentPage }: Props = this.props
    return (
      <StyledThemeProvider theme={theme}>
        <OuterContainer className="app" id="outer-container" isOpenContactModal={isModalOpen}>
          <GlobalStyle />
          {currentPage === 'home' && (
            <Head
              title="Club Gourmet - Grande Table"
              description="Sé parte del Club Gourmet más exclusivo del país."
              url="https://www.grandetable.com.ec/"
              image="https://www.grandetable.com.ec/featured-image.jpg"
            />
          )}
          {currentPage === 'benefits' && (
            <Head
              title="Beneficios - Grande Table"
              description="Disfruta de beneficios exclusivos, reservas y descuentos en los mejores restaurantes, eventos gastronómicos, delivery gratuito en tus restaurantes favoritos, y experiencias culinarias únicas."
              url="https://www.grandetable.com.ec/benefits"
              image="https://www.grandetable.com.ec/featured-image.jpg"
            />
          )}
          {currentPage === 'chefs' && (
            <Head
              title="Chefs en Casa - Grande Table"
              description="Contrata a los mejores chefs del país y disfruta de cerca de una experiencia gastronómica privada en casa."
              url="https://www.grandetable.com.ec/chefs"
              image="https://www.grandetable.com.ec/featured-image.jpg"
            />
          )}
          {currentPage === 'membership' && (
            <Head
              title="Membresías - Grande Table"
              description="Con tu membresía Grande Table podrás ser parte del club gastronómico más exclusivo del país y disfrutar de la gastronomía del más alto nivel."
              url="https://www.grandetable.com.ec/membership"
              image="https://www.grandetable.com.ec/featured-image.jpg"
            />
          )}
          {currentPage === 'events' && (
            <Head
              title="Eventos - Grande Table"
              description="Disfruta de eventos gastronómicos únicos de la mano de los mejores chefs nacionales e internacionales."
              url="https://www.grandetable.com.ec/events"
              image="https://www.grandetable.com.ec/featured-image.jpg"
            />
          )}
          {currentPage === 'restaurants' && (
            <Head
              title="Reservas - Grande Table"
              description="Reserva en los mejores establecimientos y disfruta de descuentos de hasta el 25% todos los días en toda la cuenta."
              url="https://www.grandetable.com.ec/restaurants"
              image="https://www.grandetable.com.ec/featured-image.jpg"
            />
          )}
          <Header type={themeType} toggleModal={this.toggleModal} currentPage={currentPage} />
          <PageWrap id="page-wrap" currentPage={currentPage}>
            {children}
          </PageWrap>
          <Footer type={themeType} />
          {isModalOpen && <Contact toogleModal={this.toggleModal}></Contact>}
        </OuterContainer>
      </StyledThemeProvider>
    )
  }
}

export default Layout
