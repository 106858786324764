import Theme from '../../styles/theme'
import media from '../../styles/media'

import styled from 'styled-components'
import { Link } from 'gatsby'
import theme from '../../styles/theme'

const primaryButtonColor = '#c59d5f'

interface HeaderProps {
  type: string
  StyledCustomLinkMenu?: Function
}

interface ItemProp {
  selected?: boolean
}

export const StyledHeader = styled.header`
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${(props: HeaderProps) => (props.type === 'transparent' ? 'transparent' : '#000000')};
  top: 0;
  position: ${(props: HeaderProps) => (props.type === 'transparent' ? 'absolute' : 'fixed')};
  width: 100%;
  font-family: 'San Francisco Display';
  z-index: 99;
  background-image: ${(props: HeaderProps) =>
    props.type === 'transparent'
      ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0));'
      : 'none'};
  ${media.md`  
    margin-bottom: 0;
  `};

  &.no-background-img {
    background-image: none;
  }
`
export const MenuContainer = styled.div`
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: none;
  }

  ${media.sm`
    display: flex;
  `}
`

export const StyledContainer = styled.div`
  padding: 0 30px;

  ${media.sm`
    .second-menu {
      display: none;
    }
  `}

  @media screen and (max-width: 767px) {
    .second-menu {
      margin: 0 0 0 auto;
    }
  }
`

export const StyledLogo = styled.img`
  max-width: 180px;
`

export const StyledBWLogo = styled.div<HeaderProps>`
  cursor: pointer;

  img {
    width: 100%;
    max-width: 80px;
  }

  ${media.sm`
    img {
      max-width: 90px;
    }
  `}
`

export const StyledTinyLogo = styled.img`
  max-width: 180px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(20%, -50%);
  display: ${(props: HeaderProps) => (props.type !== 'transparent' ? 'block' : 'none')};
  ${media.md`  
      display: none;
  `};
`

export const StyledList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  flex: 1 0 auto;
  justify-content: flex-end;

  .customeTip {
    background: #d8d8d8;
    color: ${theme.purpleBrown};
    text-transform: uppercase;
    width: 200px;
    border-radius: 10px;
    ul {
      margin: 0;
      list-style: none;
      li {
        margin-left: -2rem;
        padding: 5px 0;
        &:hover {
          background: transparent;
          color: 231f20;
          a {
            &:before {
              visibility: visible;
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
          }
        }
        a {
          position: relative;
          color: ${theme.purpleBrown};
          &:before {
            content: '';
            position: absolute;
            width: 110%;
            height: 1px;
            bottom: -4px;
            left: -5%;
            background-color: ${theme.purpleBrown};
            visibility: ${(props: ItemProp) => (props.selected === true ? 'visible' : 'hidden')};
            -webkit-transform: ${(props: ItemProp) =>
              props.selected === true ? 'scaleX(1)' : 'scaleX(0)'};
            transform: ${(props: ItemProp) =>
              props.selected === true ? 'scaleX(1)' : 'scaleX(0)'};
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
          }
        }
      }
    }
  }

  .__react_component_tooltip.place-bottom::after {
    border-bottom: 8px solid #d8d8d8 !important;
  }
`

export const StyledItemMenu = styled.li`
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  letter-spacing: 0.8px;
  .item-link {
    position: relative;
    .circle {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: #ff4040;
      color: #ffffff;
      font-size: 11px;
      font-family: Roboto;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      position: absolute;
      left: 70%;
      top: -2px;
    }
    .cart-icon {
      font-size: 25px;
      color: #ffffff;
    }
  }

  &.hidden {
    display: none;
  }

  .submenu {
    cursor: pointer;
    font-size: 13px;
    color: ${(props: ItemProp) =>
      props.selected === true ? Theme.secondaryColor : Theme.mainColor};
    text-transform: uppercase;
    font-weight: ${(props: ItemProp) => (props.selected === true ? 'bold' : 'normal')};
    &:hover {
      background: transparent;
      color: ${Theme.secondaryColor};
      span {
        &:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }
    span {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 110%;
        height: 1px;
        bottom: -4px;
        left: -5%;
        background-color: ${Theme.secondaryColor};
        visibility: ${(props: ItemProp) => (props.selected === true ? 'visible' : 'hidden')};
        -webkit-transform: ${(props: ItemProp) =>
          props.selected === true ? 'scaleX(1)' : 'scaleX(0)'};
        transform: ${(props: ItemProp) => (props.selected === true ? 'scaleX(1)' : 'scaleX(0)')};
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }
    }
    ${media.sm`
    font-size: 10px;
    `}
    ${media.lg`
    font-size: 13px;
    `}
  }

  .gt-login {
    ${media.sm`
    font-size: 10px;
    padding: 0.2rem 1rem;
    `}
    ${media.lg`
    font-size: 13px;
    padding: 5px 40px;
    `}
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    padding-left: 10px;
    padding-right: 10px;
    a {
      img {
        width: 80%;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    div {
      #CLICKLOGIN {
        padding: 0.2rem 1rem;
        font-size: 10px;
      }
    }
    a {
      img {
        width: 70%;
      }
    }
    .item-link {
      .circle {
        height: 14px;
        width: 14px;
        font-size: 10px;
      }
      .cart-icon {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .item-link {
      .cart-icon {
        color: ${theme.purpleBrown};
      }
    }
  }
`

export const StyledLinkMenu = styled(Link)`
  text-decoration: none !important;
  font-size: 13px;
  padding: 0;
  transition: none;
  color: ${(props: ItemProp) => (props.selected === true ? Theme.secondaryColor : Theme.mainColor)};
  text-transform: uppercase;
  font-weight: ${(props: ItemProp) => (props.selected === true ? 'bold' : 'normal')};
  span {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 110%;
      height: 1px;
      bottom: -4px;
      left: -5%;
      background-color: ${Theme.secondaryColor};
      visibility: ${(props: ItemProp) => (props.selected === true ? 'visible' : 'hidden')};
      -webkit-transform: ${(props: ItemProp) =>
        props.selected === true ? 'scaleX(1)' : 'scaleX(0)'};
      transform: ${(props: ItemProp) => (props.selected === true ? 'scaleX(1)' : 'scaleX(0)')};
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
  }
  &.dropdown-link{
    font-size: 11.5px;
    display: block;
  }
  &:hover {
    background: transparent;
    color: ${Theme.secondaryColor};
    span {
      &:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
`

export const StyledCustomLinkMenu = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  padding: 0;
  transition: none;
  color: ${(props: ItemProp) => (props.selected === true ? Theme.secondaryColor : Theme.mainColor)};
  text-transform: uppercase;
  text-align: left;
  span {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 110%;
      height: 1px;
      bottom: -4px;
      left: -5%;
      background-color: ${Theme.secondaryColor};
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
  }
  &:hover {
    background: transparent;
    color: ${Theme.secondaryColor};
    span {
      color: ${Theme.secondaryColor};
      &:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
`

export const styles = {
  bmBurgerButton: {
    position: 'relative',
    width: '25px',
    height: '20px',
    marginLeft: 'auto',
    right: '1rem',
  },
  bmBurgerBars: {
    background: 'white',
    height: '3px',
  },
  bmCrossButton: {
    display: 'none',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenu: {
    background: 'white',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMenuWrap: {},
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.9)',
    left: '0px',
  },
}

export const ContainerLogo = styled.div`
  display: block !important;
  outline: none;
  text-align: center;
`
export const ContainerItems = styled.div`
  display: flex !important;
  outline: none;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  a {
    color: ${theme.purpleBrown};
    font-family: 'SFProText';
    font-size: 13px;
    letter-spacing: 0.75px;
    cursor: pointer;
    &:hover,
    &:focus {
      color: ${primaryButtonColor};
    }
    &:focus {
      outline: none;
    }
    &:not([href]):not([tabindex]) {
      color: ${theme.purpleBrown};
    }
  }

  a.mobile-link {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    text-transform: uppercase;
  }

  .mobile-submenu {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    font-family: 'SFProText';
    font-size: 13px;
    letter-spacing: 0.75px;
    .submenu-root {
      cursor: pointer;
      span {
        color: black;
        text-transform: uppercase;
      }
      i {
        float: right;
      }
    }
    ul {
      margin: 0;
      list-style: none;
      li {
        margin-left: -2rem;
        padding: 5px 0;
        a {
          color: ${theme.purpleBrown};
        }
      }
    }
  }

  .login-container {
    margin: 30px auto 0;
    a {
      color: #ffffff;
    }
  }

  .ml-3{
    margin-left: 2rem;
  }
`
export const StyledItem = styled(Link)`
  color: black;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  &.dropdown-link {
    display: block;
  }
  &:hover,
  &:focus {
    color: ${primaryButtonColor};
  }
  &:focus {
    outline: none;
  }

  &.hidden {
    display: none;
  }
`
export const SocialContainer = styled.div`
  display: flex !important;
  justify-content: center;
`
export const StyledLoginButton = styled(Link)`
  vertical-align: middle;
  padding: 5px 40px;
  border: solid 2px ${primaryButtonColor};
  background-color: ${primaryButtonColor};
  text-transform: uppercase;
  border-radius: 17.5px;
  &:hover,
  &:focus {
    color: ${primaryButtonColor};
    border: solid 2px ${primaryButtonColor};
    background-color: #ffffff;
  }
  &:focus {
    outline: none;
  }

  &.hidden {
    display: none;
  }
`

export const Dropbtn = styled.div`
  font-size: 13px;
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
`;

export const DropDownContent = styled.div`
  padding-top: 4px;
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const StyledLi = styled.li`
  float: left;
`;

export const DropDownLi = styled.li`
  display: block;
  &:hover {
    text-decoration: true;
  }
  &:hover ${DropDownContent} {
    display: block;
  }
  .mobile{
    color: white !important;
  }
`;
