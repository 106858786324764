import styled, { ThemeProvider } from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const ModalOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledModal = styled.div`
  padding: 2rem 3rem;
  background: white;
  color: ${theme.purpleBrown};
  position: relative;
  width: 90%;
  text-align: center;

  ${media.sm`
    width: 512px;
  `}

  h5 {
    color: #c59d5f;
    font-size: 25px;
    font-family: DroidSerif;
    font-weight: normal;
  }

  p {
    line-height: 14px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      border: solid 1px #d3d3d3;
      text-align: left;
      margin-left: 0;
      margin-bottom: 10px;
      padding: 5px 10px;
      display: inline-block;
      width: 100%;

      ${media.sm`
        text-align: center;
        width: 29.3%;
        margin-left: 25px;
        margin-bottom: 0;
        padding: 15px 10px;
      `}

      &:first-child {
        margin-left: 0;
      }
      a {
        font-size: 12px;
        line-height: 14px;
        color: ${theme.purpleBrown};
        img {
          display: inline;
          margin: 0 10px;
          height: 28px;
          ${media.sm`
            display: block;
            height: auto;
            margin: 0 auto 10px;
          `}
        }
        b {
          display: inline;
          ${media.sm`
            display: block;
          `}
        }
      }
    }
  }
`

export const StyledClose = styled.span`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  font-size: 20px;
  cursor: pointer;
  color: ${theme.purpleBrown};
`
