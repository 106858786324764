import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../../../static/favicon.ico'

interface Props {
  title: 'Gatsby Bootstrap 4 Starter' | string
  description: 'Gatsby Bootstrap 4 Starter' | string
  url: 'http://example.com' | string
  image: 'http://example.com/image.jpg' | string
}

const Head = ({ title, description, url, image }: Props) => (
  <Helmet>
    <meta http-equiv="Cache-Control" content="private, max-age=691200" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <title>{title}</title>
    <link rel="icon" type="image/x-icon" href={favicon} />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <script src="https://checkout.placetopay.ec/lightbox.min.js" />
    <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"></link>
    <link href="https://fonts.googleapis.com/css?family=Roboto:300&display=swap" rel="stylesheet"></link>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css" />
  </Helmet>
)

export default Head
