import Theme from '../../styles/theme'
import media from '../../styles/media'

import styled from 'styled-components'

interface FooterProps {
  type: string
}

export const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  width: 100%;
  background: #242424;
  margin-bottom: ${(props: FooterProps) => (props.type === 'transparent' ? '3rem' : '0rem')};
  ${media.xs`  
    margin-bottom: 0rem;
  `};
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const ProviderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: auto;
`
export const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
  font-weight: 300;
  ${media.md`  
    margin: 0;  
    font-size: 1rem;
    width: auto;
  `};
`
export const StyledLogo = styled.img`
  margin: 0 1rem;
  max-width: 110px;
  ${media.md`  
    max-width: 100%;
  `};
`

export const LogosContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;

  ${media.md`  
      width: auto;
      margin-bottom:0rem;
  `};
`

export const RightsContainer = styled.div`
  flex: auto;
  font-weight: 300;
`
export const Rights = styled.p`
  margin: 0;
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 2px;
  ${media.md`  
      font-size: 1rem;
      text-align: right;
  `};
`

export const StyledTerms = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    color: white;
  }
`
