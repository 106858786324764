import styled, { keyframes } from 'styled-components'
import media from '../../../styles/media'
import theme from '../../../styles/theme'

export const Container = styled.div``

export const VerticalLine = styled.div`
  border-left: solid 1px #c4c4c4;
  margin-right: 1rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 1rem;
`

export const CollapseContainer = styled.div`
  padding: 0rem 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
`

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: auto;
  align-items: center;
  padding: 5px 0.5rem;
  &.terms {
    padding: 12px 0.5rem 5px;
  }
`

export const InputContainer = styled.div`
  flex: 2;
  position: relative;
  ${(props: { maxWidth?: string }) => (props.maxWidth ? `max-width: ${props.maxWidth}` : '')}
`

export const StyledLabel = styled.label`
  color: ${theme.purpleBrown};
  margin: 0;
  margin-right: 1rem;
  font-size: 0.9rem;
  font-weight: 300;
  font-family: 'Roboto';
  margin-bottom: 5px;
  flex: 100%;
  ${media.md`  
    margin-bottom:0; 
    flex: 1 0 10%;
  `};
`

export const StyledCheckBox = styled.label`
  color: ${(props: StyledInputProps) => (props.error === true ? 'red' : theme.gold)};
  margin: 0;
  margin-right: 1rem;
  font-family: 'Roboto';
  flex: 1 0 auto;
  font-size: 0.9rem;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background-color: #c59d5f;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
  a {
    color: ${(props: StyledInputProps) => (props.error === true ? 'red' : theme.gold)};
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: solid 1px ${theme.gold};
    border-radius: 5px;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: ${theme.gold};
    }
  }
`
type StyledInputProps = {
  error?: boolean
}

export const StyledInput = styled.input<StyledInputProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cbcbcb;
  font-family: 'Roboto';
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  line-height: normal;
  letter-spacing: 1px;
  font-family: 'Roboto';
  &:focus {
    border-color: #c59d5f;
    outline: none;
    box-shadow: 0px 0px 2px 0px #c59d5f;
  }
  &::placeholder {
    color: #cbcbcb;
  }
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  ${(props) => {
    return props.error ? 'border-color: red;' : null
  }};

  width: 100%;
`

export const StyledSelect = styled.select<StyledInputProps>`
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cbcbcb;
  font-family: 'Roboto';
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  width: 100%;
  &:focus {
    border-color: #c59d5f;
    outline: none;
    box-shadow: 0px 0px 2px 0px #c59d5f;
  }
  &::placeholder {
    color: #cbcbcb;
  }
  ${(props) => {
    if (props.error) {
      return `
        border-color: red;
      `
    }
    return null
  }};
`

type CustomButtonProps = {
  isDisabledButton?: boolean
}
export const CustomButton = styled.div<CustomButtonProps>`
  display: block;
  background: ${theme.secondaryColor};
  border: solid 1px ${theme.secondaryColor};
  padding: 0.5rem 2.5rem;
  color: white;
  text-transform: uppercase;
  border-radius: 17.5px;
  letter-spacing: 1.7px;
  max-width: 50%;
  margin: auto 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  font-family: SFProText;
  font-size: 14px;

  ${(props) => {
    return props.isDisabledButton ? 'opacity: 0.6;' : null
  }};

  &:hover {
    background: white;
    color: ${theme.secondaryColor};
  }
  ${media.sm`  
    max-width: 100%;
    padding: 0.5rem 1.5rem;
    margin: auto auto;
  `};
`

export const YesNoQuestion = styled.div`
  display: flex;
  flex-direction: row;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;

  div {
    margin-bottom: 0.5rem;
  }

  ${media.sm`
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;

    div {
      float: right;
      width: initial;
      margin-left: 0.5rem;
    }

    span {
      float: left;
      width: initial;
      margin-bottom: 0.5rem;
    }
  `};
`

export const ButtonContainerCustom = styled.div`
  padding: 0rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${media.md`  
    justify-content: space-between;
  `};
`

export const CustomContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

type BackButtonProps = {
  color?: string
  size?: string
  withoutMargin?: boolean
}

export const BackButton = styled.span`
  display: block;
  color: ${(props: BackButtonProps) => {
    return props.color ? props.color : theme.purpleBrown
  }};
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  margin: auto auto;
  font-family: SFProText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.81px;
  text-align: right;
  float: left;
  
  ${media.sm`  
    display: flex;
    padding: 0.5rem 2.5rem;
    margin: 0px 0px;
  `};
`

type BackProps = {
  show: boolean
  changeStep?: number
}

export const StyledBack = styled.span`
  color: #ed3939;
  margin-left: 1rem;
  cursor: pointer;
  ${(props: BackProps) => {
    if (!props.show) {
      return `
        display: none;
      `
    }
    return null
  }};
`

export const StyledTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-bottom: 0;
  padding: 5px 1rem;
`
const enter = keyframes`
  from{
    font-size: 0rem;
  }
  to{
    font-size: 0.8rem;
  }
  
`

export const StyledError = styled.p`
  margin-bottom: 0;
  color: red;
  margin-top: 2px;
  font-size: 0.8rem;
  animation: ${enter} 0.1s cubic-bezier(0.83, 0.36, 0.58, 0.99);
  text-align: right;
`
