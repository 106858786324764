import styled, { ThemeProvider } from 'styled-components'

interface Props {
  children: any
  themeType?: string
  isOpenContactModal?: boolean
  currentPage?: string
}

export const StyledThemeProvider = styled(ThemeProvider)`
  max-width: 100%;
`

export const PageWrap = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  position: relative;
  margin-top: ${(props: Props) => (props.currentPage == 'home' ? '0' : '72px')};
  .no-side-margins {
    margin-left: 0;
    margin-right: 0;
  }
  .width-100 {
    width: 100%;
  }
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  overflow: ${(props: Props) => (props.isOpenContactModal === true ? 'hidden' : 'auto')};
`

export const StyledTile = styled.h3`
  text-align: center;
  font-weight: bold;
`

export const CustomText = styled.p`
  text-align: center;
`
