import { Link } from 'gatsby'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { FacebookIconB, InstagramIconB, Logo } from '../../../assets/images/general'
import AuthContext from '../../../context/auth-context'
import { ContainerItems, ContainerLogo, SocialContainer, StyledCustomLinkMenu, StyledItem, StyledItemMenu, StyledLogo, styles } from '../style'

interface MobileHeaderProps {
  toggleModal: () => void
}

export default function MobileHeader(props: MobileHeaderProps) {
  const { toggleModal } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="second-menu">
      <Menu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} right width={'90%'} styles={styles} isOpen={isOpen} onStateChange={(state) => setIsOpen(state.isOpen)}>
        <ContainerLogo>
          <StyledLogo src={Logo} />
        </ContainerLogo>
        <ContainerItems>
          <StyledItem className="GTABLE_MENU" data-menu="Home" to="/" onClick={() => setIsOpen(false)}>
            Home
          </StyledItem>
          <StyledItem className="GTABLE_MENU" data-menu="Restaurantes" to="/restaurants" onClick={() => setIsOpen(false)}>
            Reservas
          </StyledItem>
          <StyledCustomLinkMenu className="GTABLE_MENU" data-menu="Delivery" href="https://delivery.grandetable.com.ec" target="_blank" onClick={() => setIsOpen(false)}>
            Delivery
          </StyledCustomLinkMenu>

          {process.env.WITH_BURGER_FEST === 'active' ? (
            <ContainerItems>
              <StyledItem className="GTABLE_MENU ml-3" data-menu="Event" to="/events" onClick={() => setIsOpen(false)}>
                Eventos del Mes
              </StyledItem>
              <StyledItem className="GTABLE_MENU ml-3" data-menu="Event" to="/events/burger-fest" onClick={() => setIsOpen(false)}>
                Burger Fest
              </StyledItem>
            </ContainerItems>
          ) : (
            <StyledItem className="GTABLE_MENU" data-menu="Event" to="/events" onClick={() => setIsOpen(false)}>
              Eventos
            </StyledItem>
          )}
          <StyledItem className="GTABLE_MENU" data-menu="Membresías" to="/membership" onClick={() => setIsOpen(false)}>
            Membresías
          </StyledItem>

          <StyledItem className="GTABLE_MENU" data-menu="Chefs" to="/chefs" onClick={() => setIsOpen(false)}>
            Chef en Casa
          </StyledItem>
          <StyledItem className="GTABLE_MENU" data-menu="Beneficios" to="/benefits" onClick={() => setIsOpen(false)}>
            Beneficios
          </StyledItem>
          <StyledItem className="GTABLE_MENU" data-menu="FAQ" to="/faq" onClick={() => setIsOpen(false)}>
            FAQ
          </StyledItem>
          <StyledItem className="GTABLE_MENU" data-menu="Blog" to="/blog" onClick={() => setIsOpen(false)}>
            Blog
          </StyledItem>
          <a
            className="GTABLE_MENU mobile-link"
            data-menu="Contacto"
            onClick={() => {
              setIsOpen(false)
              toggleModal()
            }}
          >
            <span>Contacto</span>
          </a>
        </ContainerItems>
        <SocialContainer>
          <StyledItemMenu>
            <a id="GTABLE_FB" href="https://www.facebook.com/gtclubgourmet" target="_blank" onClick={() => setIsOpen(false)} rel="noreferrer">
              <img src={FacebookIconB} alt="Grande Table Facebook" />
            </a>
          </StyledItemMenu>
          <StyledItemMenu>
            <a id="GTABLE_INST" href="https://www.instagram.com/grandetableclubgourmet/" target="_blank" onClick={() => setIsOpen(false)} rel="noreferrer">
              <img src={InstagramIconB} alt="Grande Table Instagram" />
            </a>
          </StyledItemMenu>
          <StyledItemMenu>
            <AuthContext.Consumer>
              {(context: any) => {
                return context.purchase ? (
                  <Link id="GTABLE_CART" className="item-link" to="/cart" onClick={() => setIsOpen(false)}>
                    {context.purchase.items.length === 0 ? '' : <div className="circle">{context.purchase.items.length}</div>}
                    <i className="icon-carrito cart-icon" />
                  </Link>
                ) : (
                  <Link id="GTABLE_CART" className="item-link" to="/cart" onClick={() => setIsOpen(false)}>
                    <i className="icon-carrito cart-icon" />
                  </Link>
                )
              }}
            </AuthContext.Consumer>
          </StyledItemMenu>
        </SocialContainer>
      </Menu>
    </div>
  )
}
