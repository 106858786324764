export default {
  mainColor: '#ffffff',
  secondaryColor: '#c59d5f',
  camel: '#c59d5f',
  lightGray: '#f9f9f9',
  purpleBrown: '#231f20',
  veryLightPink: '#d9d9d9',
  blue: '#13274a',
  grey: '#6a6a6a',
  gold: '#c69d5f',
  lightGold:' #fffcf8',
  lightGold2: '#efdcbf',
  black: '#2f2f2f',
  lightGrey2: '#787878',
  lightGrey3: '#c6c6c6',
}
