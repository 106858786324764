import { css } from 'styled-components'

const sizes: any = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200
}
// iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((accumulator: any, label: string) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  accumulator[label] = (...args: any[]) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(args[0], ...args)};
    }
  `
  return accumulator
}, {})
