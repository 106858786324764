import React from 'react'
import { LogosContainer, ProviderContainer, Rights, RightsContainer, StyledContainer, StyledFooter, StyledLogo, StyledTerms, Text } from './style'

const DinersLogo = require('../../assets/images/general/diners-logo.svg')

interface Props {
  type: string
}

const Footer = ({ type }: Props) => (
  <StyledFooter type={type}>
    <StyledContainer className="container">
      <ProviderContainer>
        <Text>Patrocinado por:</Text>
        <LogosContainer>
          <StyledLogo src={DinersLogo} />
        </LogosContainer>
      </ProviderContainer>
      <RightsContainer>
        <Rights>Derechos reservados. Diners Club Ecuador {new Date().getFullYear()}</Rights>
        <Rights>
          <StyledTerms href="https://medias.grandetable.com.ec/docs/tyc_grande_table.pdf" target="_blank">
            Términos y condiciones/
          </StyledTerms>
          <StyledTerms href="https://medias.grandetable.com.ec/docs/politicas_de_privacidad.pdf" target="_blank">
            Políticas de privacidad
          </StyledTerms>
        </Rights>
      </RightsContainer>
    </StyledContainer>
  </StyledFooter>
)

export default Footer
