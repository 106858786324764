import React, { Component } from 'react'
import { ModalOverlay, StyledModal, StyledClose } from './style'
import { getWhatsappLink } from '../../helpers/whatsapp-link'

const mailIcon = require('../../assets/images/general/mail.svg')
const phoneIcon = require('../../assets/images/general/phone.svg')
const whatsappIcon = require('../../assets/images/general/whatsapp.svg')

class Contact extends Component<{ toogleModal: any }, any> {
  render() {
    return (
      <ModalOverlay>
        <StyledModal>
          <StyledClose onClick={this.props.toogleModal}>x</StyledClose>
          <h5>Contacto</h5>
          <p>Comunícate con nuestro equipo de soporte.</p>
          <ul>
            <li>
              <a href="mailto:info@grandetable.com.ec" target="_self">
                <img src={mailIcon}></img>
                Correo electrónico <b>&nbsp;</b>
              </a>
            </li>
            <li>
              <a href={getWhatsappLink(navigator.userAgent, screen.availWidth)} target="_blank">
                <img src={whatsappIcon}></img>
                Whatsapp <b>&nbsp;</b>
              </a>
            </li>
            <li>
              <a href="tel:+59325001100">
                <img src={phoneIcon}></img>
                Teléfono <b>02 500 1100</b>
              </a>
            </li>
          </ul>
        </StyledModal>
      </ModalOverlay>
    )
  }
}

export default Contact
