import Theme from '../../styles/theme'
import media from '../../styles/media'
import styled from 'styled-components'

interface LinkProps {
  children: any
  type?: string
  link: string
}

export const StyledButton = styled.div`
  background: ${(props: LinkProps) =>
    props.type === 'primary' ? Theme.secondaryColor : 'transparent'};
  border: solid
    ${(props: LinkProps) =>
      props.type === 'primary' ? `2px ${Theme.secondaryColor}` : '1px white'};
  padding: 0.5rem 2.5rem;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  border-radius: 17.5px;
  letter-spacing: 1.7px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 300;
  margin: auto;

  ${(props: LinkProps) => {
    if (props.link === 'membership') {
      return `
    width: 100%;
    border-radius: 0;
    position: fixed;
    bottom: 0;
    z-index: 98;
    left: 0;
    padding: 1rem 1rem;
  `
    }
    if (props.link === 'restaurant') {
      return `
        width: 113px !important;
        height: 33px;
        font-family: 'San Francisco Display';
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.63px;
        padding: 0.65rem 0 !important;
      `
    } else {
      return `display: block;`
    }
  }} &:hover {
    background: white;
    color: ${(props: LinkProps) => (props.type === 'primary' ? Theme.secondaryColor : '#242424')};
  }
  ${media.xs`  
    display: inline-block;  
    width: auto;
    position: relative;
    padding: 0.5rem 2.5rem;
    border-radius: 17.5px;
    margin: auto;
  `};
  ${media.sm`
  &.btn-width {
    width: 50%;
  }
  `}
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${media.md`  
    align-items: center;
  `};
`

export const StyledAccountButton = styled.button`
  outline: none;
  background: transparent;
  border: solid 2px ${Theme.secondaryColor};
  padding: 0.5rem 1.5rem;
  color: black;
  border-radius: 17.5px;
  letter-spacing: 1.7px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 300;
  margin: auto;
  display: block;
  span {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  &:hover {
    background: white;
    color: ${Theme.secondaryColor};
  }

  ${media.sm`  
    display: inline-block;  
    width: auto;
    position: relative;
    padding: 0.5rem;
    border-radius: 17.5px;
    margin: auto;
    color: white;
    font-size: 10px;
  `};

  ${media.md`  
    padding: 0.5rem 1.5rem;
    font-size: inherit;
  `};
`
